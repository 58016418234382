import React from "react"
// @material-ui/core components
// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"

import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";
import { mapService, treatmentsService } from "../../_services";
import Table from "../../components/Table";

import GreenhouseMap from "./GreenhouseMap";
import MyDropdown from "../../components/MyDropdown";
import queryString from "query-string";
import { Link } from "react-router-dom";
import NavPills from "../../components/NavPills";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import extendedTablesStyle from "./extendedTablesStyle";
import Button from "../../components/CustomButtons";
import { beautify_name } from "../helpers";


class Maps extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      map_ids: [],
      map_id: undefined,
      map_versions: [],
      map_version: undefined,
      map_sync_information: {},
      map_detail: {},
      crop_state: {},
      greenhouse: {},
      layer_names: [],
      layer_name: "sites",
      map_change_log: [],
      map_detail_list: [],
      gutter_version: undefined,
      obstacle_version: undefined,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    const { map_id, map_version } = queryString.parse(this.props.location.search)
    if (map_id) {
      this.setState({ map_id, map_version }, () => {
        this.refresh()
      })
    } else {
      this.refresh()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  recalculate_crop_state() {
    const { map_id } = this.state
    this.setState({ crop_state: {} })
    treatmentsService.getCropState(map_id, res => {
      this.setState({ crop_state: res.result })
    })
  }

  refresh() {
    const { map_id } = this.state

    mapService.getMapIds(result => {
      this.setState({ map_ids: result.results })
    })
    mapService.getMapVersions(map_id, res => {
      this.setState({ map_versions: res.versions })
    })
    if (map_id) {
      mapService.getGreenhouseByMapID(map_id, res => {
        this.setState({ greenhouse: res.greenhouse })
      })
    }

    mapService.getMapDetail(map_id,
      res => {
        let map_detail_list = []
        let versions = [<b> Layers </b>]
        let raw_versions = []
        let empty = [""]

        let temp_layer_names = []
        let layer_names = [{"id": "sites", "name": "Site"}]

        res.result["versions"].forEach((version_info) => {
          versions.push(<b> {version_info["version"]} </b>)
          raw_versions.push(version_info["version"])
          empty.push("")

          version_info["layers"].forEach((layer) => {
            if (!temp_layer_names.includes(layer["name"])) {
              temp_layer_names.push(layer["name"])
            }
          })
        })
        map_detail_list.push(versions)

        temp_layer_names.forEach((layer_name) => {
          if (!(["sites", "nodes", "connections", "waypoints"].includes(layer_name))) {
            layer_names.push({
              "id": layer_name,
              "name": beautify_name(layer_name),
            })

            let layer_row = [layer_name]
            raw_versions.forEach((version) => {
              let temp_version_info = {}
              res.result["versions"].forEach((version_info) => {
                if (version_info["version"] === version) {
                  temp_version_info = version_info
                }
              })
              if (Object.keys(temp_version_info).includes("layers")) {
                temp_version_info["layers"].forEach((layer) => {
                  let layer_versions = layer["versions"].map((l_version) => {
                    return l_version.replace(version + '.', "")
                  })
                  if (layer["name"] === layer_name) {
                    layer_row.push(layer_versions.join(', '))
                  }
                })
              }
            })
            map_detail_list.push(layer_row)
          }
        })
        map_detail_list.push(empty)
        this.refresh_changelog()

        this.setState({
          map_detail: res.result,
          map_detail_list,
          layer_names
        })
      },
      reject => {
        this.setState({})
      })
  }

  handle_form_input_selector(name, value) {
    if (name === "map_version" || name === "map_id") {
      this.setState({ gutter_version: undefined, obstacle_version: undefined, [name]: value }, () => this.refresh())
      if (name === "map_id"){
        this.setState({layer_name: "sites"}, () => this.refresh_changelog())
      }
    } else {
      this.setState({ [name]: value }, () => this.refresh())
    }
  }

  refresh_changelog() {
    const { map_id, layer_name } = this.state
    mapService.getMapChanges(map_id, layer_name, res => {
      let map_change_log = [[<b>Version</b>, <b>Changes</b>]]

      Object.keys(res).forEach((map_version) => {
        map_change_log.push([map_version, res[map_version]])
      })

      map_change_log.push(["", ""])

      this.setState({ map_change_log: map_change_log })
    })
  }

  handle_layer_name(name, value) {
    this.setState({ [name]: value }, () => this.refresh_changelog())
  }

  render() {
    const {
      map_detail_list,
      gutter_version,
      obstacle_version,
      map_detail,
      greenhouse,
      map_ids,
      map_id,
      map_versions,
      map_version,
      layer_names,
      layer_name,
      map_change_log,
      crop_state,
    } = this.state

    let gutter_versions = []
    let obstacle_versions = []

    if (map_detail !== {} && map_detail["versions"] !== undefined) {
      map_detail["versions"].forEach((version) => {
        if (version["version"] === map_version) {
          version["layers"].forEach((layer_versions) => {
            if (layer_versions["name"] === "gutters") {
              gutter_versions = layer_versions["versions"]
            }
            if (layer_versions["name"] === "obstacles") {
              obstacle_versions = layer_versions["versions"]
            }
          })
        }
      })
    }

    let map_detail_table = [
      ["Name", map_detail["map_name"]],
      ["Greenhouse Type", map_detail["greenhouse_type"]],
      ["Timezone", greenhouse ? greenhouse["timezone"] : "?"],
      ["Greenhouse Name", greenhouse ? greenhouse["name"] : "?"],
    ]
    map_detail_table.push(["", ""])

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <h4 style={{ color: primaryColor }}>
                    <b>{map_detail["map_name"] ? map_detail["map_name"] : map_id}</b>
                  </h4>
                  <Link to={`/maps/maps`}>To Overview </Link>
                  <GridContainer>
                    <GridItem xs={12} md={6}>
                      <MyDropdown
                        input_label={"Map ID"}
                        name={"map_id"}
                        value={map_id}
                        default_value={map_id}
                        options={[...map_ids.map((item) => {
                          return { id: item, value: item }
                        })]}
                        handle_selection={this.handle_form_input_selector.bind(this)}
                      />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <MyDropdown
                        input_label={"Map Version"}
                        name={"map_version"}
                        value={map_version}
                        default_value={map_version}
                        options={[...map_versions.map((item) => {
                          return { id: item, value: item }
                        })]}
                        handle_selection={this.handle_form_input_selector.bind(this)}
                      />
                </GridItem>

                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Table
                    tableHeaderColor="primary"
                    tableData={map_detail_table}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <NavPills
                tabs={
                  [
                    {
                      tabButton: "Map View",
                      tabContent: <GridContainer>
                        <GridItem xs={12} md={4}>
                          <MyDropdown
                            input_label={"Gutter Version"}
                            name={"gutter_version"}
                            value={gutter_version}
                            default_value={gutter_version}
                            options={[{
                              id: undefined,
                              value: "No Gutters"
                            }, ...gutter_versions.map((item) => {
                              return { id: item, value: item }
                            })]}
                            handle_selection={this.handle_form_input_selector.bind(this)}
                          />
                        </GridItem>
                        <GridItem xs={12} md={4}>
                          <MyDropdown
                            input_label={"Obstacle Version"}
                            name={"obstacle_version"}
                            value={obstacle_version}
                            default_value={obstacle_version}
                            options={[{
                              id: undefined,
                              value: "No Obstacles"
                            }, ...obstacle_versions.map((item) => {
                              return { id: item, value: item }
                            })]}
                            handle_selection={this.handle_form_input_selector.bind(this)}
                          />
                        </GridItem>
                        <GridItem xs={12} md={4}>
                          <Button
                            color={"primary"}
                            onClick={this.recalculate_crop_state.bind(this)}
                          >
                            Recalculate Crop State
                          </Button>
                        </GridItem>
                        <GridItem xs={12} md={12}>
                          <GreenhouseMap
                            map_id={map_id}
                            map_version={map_version}
                            gutter_layer_version={gutter_version}
                            obstacle_layer_version={obstacle_version}
                            crop_state={crop_state}
                          />
                        </GridItem>
                      </GridContainer>
                    },
                    {
                      tabButton: "Map Layers & Versions",
                      tabContent: <div>
                        <Table
                          color={"primary"}
                          tableData={map_detail_list}
                        />
                      </div>
                    },
                    {
                      tabButton: "Map Changelog",
                      tabContent: <div>
                        <MyDropdown
                          input_label={"Layer Name"}
                          name={"layer_name"}
                          value={layer_name}
                          default_value={"sites"}
                          options={[...layer_names.map((item) => {
                            return { id: item.id, value: item.name }
                          })]}
                          handle_selection={this.handle_layer_name.bind(this)}
                        />
                        <Table
                          color={"primary"}
                          tableData={map_change_log}
                        />
                      </div>
                    },
                  ]
                }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  return {}
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Maps))
