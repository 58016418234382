import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";

import ReactTable from "../../components/react-table";
import Button from "../../components/CustomButtons";
import {sweetAlertActions} from "../../_actions";
import CustomInput from "../../components/CustomInput";
import NavPills from "../../components/NavPills";
import {update_organisation_list} from "../helper_functions/locations";
import ClipboardButton from "../../components/ClipboardButoon";
import {getUserType} from "../../_services/authenticate";

class Organisations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalResults: 0,
      totalPages: 0,
      pageSize: 10,
      currentPage: 1,
      loading: false,
      updated_properties: {},
      data: [],
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {currentPage, pageSize} = this.state
    this.setState({loading: true})

    mapService.getOrganisations(pageSize, currentPage - 1, result => {
      const total_pages = Math.ceil(result.total / pageSize)
      let newCurrentPage = currentPage
      if (currentPage > total_pages && result.total > 0) {
        newCurrentPage = total_pages
      }

      this.setState({
        totalResults: result.total,
        currentPage: newCurrentPage,
        data: result.results,
        totalPages: total_pages,
        loading: false,
      })
    }, error => {
      this.setState({
        data: [],
        totalPages: 0,
        loading: false
      })
    });
  }

  change_value(value_name, value) {
    let {updated_properties} = this.state
    updated_properties[value_name] = value
    this.setState({updated_properties})
  }

  start_creating_organisation() {
    const {dispatch} = this.props
    const {updated_properties} = this.state
    dispatch(sweetAlertActions.clean({
      title: "Create New Organisation",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.create_new_organisation.bind(this),
      confirmBtnText: "Yes",
      content: <div>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"name"}
          labelText={"Name"}
          inputProps={{
            onChange: event => this.change_value("name", event.target.value),
            type: "text",
            value: updated_properties["name"]
          }}
        />
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"client_name"}
          labelText={"Client Name"}
          inputProps={{
            onChange: event => this.change_value("client_name", event.target.value),
            type: "text",
            value: updated_properties["client_name"]
          }}
        />
      </div>
    }))

  }

  start_updating_organisation(organisation_id, organisation) {
    const {dispatch} = this.props
    const {updated_properties} = this.state

    dispatch(sweetAlertActions.clean({
      title: "Update Organisation",
      showCancel: true,
      onCancel: this.clear_popup.bind(this),
      cancelBtnText: "No",
      onConfirm: this.update_organisation.bind(this, organisation_id, organisation),
      confirmBtnText: "Yes",
      content: <div>

        <p><b>Leave empty if you do not want to update the property</b></p>
        <p><b>Name:</b> {organisation["name"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"name"}
          labelText={"New name"}
          inputProps={{
            onChange: event => this.change_value("name", event.target.value),
            type: "text",
            value: updated_properties["name"]
          }}
        />
        <p><b>Client Name:</b> {organisation["client_name"]}</p>
        <CustomInput
          style={{
            fullWidth: true,
            width: "100%"
          }}
          id={"client_name"}
          labelText={"New client name"}
          inputProps={{
            onChange: event => this.change_value("client_name", event.target.value),
            type: "text",
            value: updated_properties["client_name"]
          }}
        />
      </div>
    }))
  }

  update_organisation(organisation_id, organisation) {
    const {updated_properties} = this.state
    const {dispatch} = this.props;

    let new_name = undefined
    if (updated_properties["name"] !== undefined) {
      if (updated_properties["name"] !== organisation["name"]) {
        new_name = updated_properties["name"]
      }
    }

    let new_client_name = undefined
    if (updated_properties["client_name"] !== undefined) {
      if (updated_properties["client_name"] !== organisation["client_name"]) {
        new_client_name = updated_properties["client_name"]
      }
    }

    mapService.updateOrganisation(organisation_id, new_name, new_client_name, res => {
      dispatch(sweetAlertActions.clear())
      update_organisation_list()
      this.setState({
        updated_properties: {}
      }, () => this.refresh())
    })
  }

  create_new_organisation() {
    const {updated_properties} = this.state
    const {dispatch} = this.props;
    mapService.createOrganisation(updated_properties["name"], updated_properties["client_name"], res => {
      dispatch(sweetAlertActions.clear())
      update_organisation_list()
      this.setState({
        updated_properties: {}
      }, () => this.refresh())
    })
  }

  clear_popup() {
    const {dispatch} = this.props;
    dispatch(sweetAlertActions.clear())
    this.setState({
      updated_properties: {}
    }, () => this.refresh())
  }

  render() {
    const {
      currentPage,
      data,
      pageSize,
      loading,
      totalResults,
      totalPages,
    } = this.state

    let filler_size = (currentPage - 1) * pageSize
    if (filler_size < 0) {
      filler_size = 0
    }
    let filler_array = Array(filler_size).keys()
    let columns = [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        sortable: false,
        maxWidth: 50,
        Cell: row => {
          return <ClipboardButton text={row.value}/>
        }
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Client Name",
        accessor: "client_name",
      },
    ]
    if (getUserType() === "system_admin") {
      columns.push(
        {
          Header: "Auth Groups",
          accessor: "authentication_group",
        })

    }
    columns.push(
      {
        Header: "Actions",
        accessor: "id",
        Cell: row => {
          return <Button
            color="primary"
            simple
            onClick={this.start_updating_organisation.bind(this, row.value, row.original)}
          >
            <b>{"Update Organisation"}</b>
          </Button>
        }
      }
    )

    let tabs = [
      {
        tabButton: "Octiva",
        tabContent: <GridContainer>
          <GridItem xs={10}>
            <h5># Results: {totalResults}</h5>
          </GridItem>
          <GridItem xs={2} style={{textAlign: "right"}}>
            <Button color="primary" simple
                    onClick={this.start_creating_organisation.bind(this)}><b>{"Create Organisation"}</b></Button>
          </GridItem>
          <GridItem xs={12}>
            <ReactTable
              columns={columns}
              data={[
                ...filler_array,
                ...data
              ]}
              sortable={false}
              filterable={false}
              loading={loading}
              pages={totalPages}
              page={currentPage - 1}
              pageSize={pageSize}
              onPageChange={(pageIndex) => this.setState({currentPage: pageIndex + 1}, () => this.refresh())}
              onPageSizeChange={(newPageSize) => {
                this.setState({
                  currentPage: 1,
                  pageSize: newPageSize
                }, () => this.refresh())
              }}
            />
          </GridItem>
        </GridContainer>,
      }
    ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}><b>{"Overview Organisations"}</b></h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <NavPills
                tabs={tabs}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  return {
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Organisations))
