import React from "react"
import {connect} from 'react-redux'
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

import extendedTablesStyle from "./extendedTablesStyle.jsx"

import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {mapService} from "../../_services";
import OrganisationDiagram from "./OrganisationDiagram";
import NavPills from "../../components/NavPills";
import MyDropdown from "../../components/MyDropdown";

class Organisations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organisation_id: "320162df-4e90-477c-b62b-a72d97806f41",
      users: [],
      organisation: {},
      all_organisations: [],
      first_name: "",
      last_name: "",
      user_email: "",
      role: "",
      add_existing_user: false,
      add_new_user: false,
    }

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  refresh() {
    const {organisation_id} = this.state

    mapService.getOrganisation(organisation_id, res => {
      this.setState({organisation: res.result})
    })
  }

  handle_form_input_selector(name, value) {
    this.setState({[name]: value}, this.refresh.bind(this))
  }

  render() {
    const {organisations} = this.props
    const {
      organisation_id,
      organisation,
    } = this.state

    let tabs =
      [
        {
          tabButton: "Diagram",
          tabContent: <OrganisationDiagram organisation_id={organisation_id}/>,
        },
      ]

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridContainer>
                <GridItem xs={10}>
                  <h4 style={{color: primaryColor}}>
                    <b>{"Overview Organisation: " + organisation["name"]}</b></h4>
                </GridItem>
                <GridItem xs={2}>
                  <MyDropdown
                    input_label={"Organisation"}
                    name={"organisation_id"}
                    value={organisation_id}
                    default_value={organisation_id}
                    options={[...Object.keys(organisations).sort((a, b) => {
                      return organisations[a]["name"] < organisations[b]["name"] ? -1 : 1
                    }).map((org_id) => {
                      return {id: org_id, value: organisations[org_id].name}
                    })]}
                    handle_selection={this.handle_form_input_selector.bind(this)}
                  />
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <NavPills
                tabs={tabs}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    )
  }
}


function mapStateToProps(state) {
  const {organisations} = state.location
  return {
    organisations
  }
}


export default withStyles(extendedTablesStyle)(connect(mapStateToProps)(Organisations))
